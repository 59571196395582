@charset "UTF-8";

// 1ページあたりの設定
.invoice-page {
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 210mm;
  height: 297mm;
  padding: 14mm;
  font-family: "ＭＳ Ｐゴシック", "MS PGothic", メイリオ, "VL Gothic", sans-serif;
  font-size: 8pt;
  page-break-after: always;
  background-color: #ffffff;
  &:last-child {
    page-break-after: auto;
  }

  li {
    line-height: 1.5em;
  }
}

@media screen {
  .invoice-page {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #dddddd;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  }
}

.invoice-row {
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
  border-bottom: 1px solid #000;
  .empty-rows &,
  &.empty-row {
    opacity: 0;
  }
}


// ヘッダー部
.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  line-height: 1.5em;
  &-left {
    width: 60%;
  }
  &-right {
    width: 40%;
    text-align: right;
  }

  h1 {
    position: relative;
    margin-top: 0;
    margin-bottom: 25px;
    padding-left: 15px;
    font-size: 13pt;
    letter-spacing: 11pt;
    font-weight: 700;
    border-left: 3px solid #000;
    line-height: 1.2em;
    &::before {
      position: absolute;
      left: 2px;
      bottom: 0;
      content: "";
      width: 1px;
      height: 100%;
      background-color: #000;
    }
  }

  // お客様情報
  .customer-info {
    height: 85px;
  }
  .customer-name {
    width: 270px;
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 9.5pt;
    border-bottom: 1px solid #000;
    align-items: center;
    dd {
      white-space: nowrap;
    }
  }
  .customer-address {
    display: block;
    min-height: 3em;
    li {
      display: block;
      line-height: 1.5em;
    }
  }

  // 請求書タイトル・方法
  .invoice-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 9.5pt;
  }

  .invoice-method {
    display: flex;
    flex-wrap: wrap;
    .monospace {
      letter-spacing: 1em;
      margin-right: -1em;
      text-align-last: justify;
    }
    dt {
      width: 26%;
    }
    dd {
      width: 74%;
    }
  }

  .logo {
    margin-bottom: 5px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);    
  }
  .company-info {
    line-height: 1.4em;
  }
  .publisher {
    width: 140px;
    margin-left: auto;
  }
}

// 請求部
.invoice-body {

  .invoice-total {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 15px;
    padding-left: 2px;
    font-size: 10pt;
    font-weight: bold;
    border-bottom: 1px solid #000000;
  }

  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    line-height: 24px;
    table-layout: fixed;

    .indent {
      padding-left: 20px;
    }
    .left-text {
      text-align: left;
    }
    .center-text {
      text-align: center;
    }
    .right-text {
      text-align: right;
    }

    .top-border {
      border-left: 2px solid #000000;
    }

    .right-border {
      border-left: 2px solid #000000;
    }

    .bottom-border {
      border-left: 2px solid #000000;
    }

    .left-border {
      border-left: 2px solid #000000;
    }

    th,
    td {
      padding-left: 5px;
      padding-right: 5px;
    }

    thead {
      border-top: 2px solid #000000;
      border-left: 2px solid #000000;
      border-right: 2px solid #000000;
      border-bottom: 4px double #000000;
      th {
        text-align: center;
        background-color: #aaaaaa;
        color: #ffffff;
      }
    }

    tbody {
      border-left: 2px solid #000000;
      border-right: 2px solid #000000;
      tr {
        background: #FFFFFF;
      }
      .invoice-table-subtotal {
        background-color: #DDDDDD;
        border-bottom: 1px solid #FFFFFF;
      }
      .invoice-detail-odd {
        background-color: #F6F6F6;
      }

      tr:last-child {
        td:nth-child(-n+3) {
          border-bottom: 2px solid #000000;
        }
      }
    }

    &.type-invoice tbody tr {
      background-color: #FFFFFF;
      &:nth-child(odd) {
        background-color: #DDDDDD;
      }
    }

    tfoot {
      border-right: 2px solid #000000;

      .invoice-table-subtotal {
        background-color: #DDDDDD;
      }

      // tr {
      //   border-left-width: 0;
      //   &:first-child {
      //     border-top: 2px solid transparent;
      //     border-right: 2px solid #000000;
      //   }
      // }
    }

    .invoice-table-no {
      padding-right: 8px;
      text-align: right;
    }

    .invoice-table-price {
      padding-left: 30px;
      text-align: right;
      &.right-align {
        padding-left: 0;
        padding-right: 30px;
      }
    }

    .invoice-table-unit {
      padding-left: 20px;
      text-align: right;
      &.right-align {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    .invoice-table-spacer {
      // border-top: 2px solid #000000;
      // border-right: 2px solid #000000;
    }

    .invoice-table-total {
      border-top: 4px double #000000;
    }
    .last-row {
      border-bottom: 2px solid #000000;
    }
  }
}

// 特記事項
.invoice-note {

  h2 {
    font-size: 9.5pt;
    font-weight: normal;
    &::before {
      content: "■";
      margin-right: 5px;
    }
  }

  h4 {
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
  }

  .invoice-note-content {
    min-height: 220px;
    padding: 3px;
    border: 2px solid #000000;
    line-height: 1.3em;
    &.short {
      margin-bottom: 2px;
      min-height: 85px;
    }
  }

  .invoice-account-notice {
    margin-bottom: 2px;
    padding: 5px;
    line-height: 1.3em;
  }

  .invoice-account-info {
    padding: 3px;
    border: 2px solid #000000;
    line-height: 1.3em;
    dt, dd {
      display: inline-block;
    }
    dt {
      width: 60px;
    }
    dd {
      width: calc(100% - 60px);
      &::before {
        content: ":";
        padding-right: 10px;
      }
    }
  }

}
