@import "./normalize.scss";
@import "./setting.scss";

@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);

/* A4縦用のベース設定 */
@page {
  size: A4 portrait; /* 横の場合はlandscape */
  margin: 0mm;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// 基本の設定
body, pre {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Meiryo UI", Meiryo, メイリオ, Osaka, "MS PGothic",
    arial, helvetica, sans-serif;
  font-size: 13pt;
  line-height: 1.5em;
}

body {
  background-color: #e0e0e0;
}

nav {
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
}

table:not(.invoice-table) {
  width: 100%;
  margin-bottom: 10mm;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  table-layout: fixed;
  border-collapse: collapse;

  &.low-margin {
    margin-bottom: 2mm;
  }

  .headline {
    width: 50mm;
  }

  .value {
    width: 29mm;
  }
  .price {
    width: 50mm;
  }

  thead {
    th,
    td {
      padding: 2mm;
      font-weight: 400;
      text-align: left;
      background-color: $stripe_color;
      border-right: 1px solid $stripe_white;
    }
    tr > :last-child {
      border-right-width: 0 !important;
    }
  }

  .tleft {
    text-align: left;
  }
  .tcenter {
    text-align: center;
  }
  .tright {
    text-align: right;
  }

  .back-gray {
    border-right-color: $stripe_white !important;
    background-color: $stripe_color !important;
    th,
    td {
      border-right-color: $stripe_white !important;
      background-color: $stripe_color !important;
    }
  }
  .back-white {
    border-right-color: $stripe_bordercolor !important;
    background-color: $stripe_white !important;
    th,
    td {
      border-right-color: $stripe_bordercolor !important;
      background-color: $stripe_white !important;
    }
  }

  thead + tbody {
    tr:first-child {
      th,
      td {
        border-top: 3px solid #7f7f7f;
      }
    }
  }

  tbody,
  tfoot {
    th,
    td {
      padding: 2mm;
      font-weight: 400;
      border-right: 1px solid $stripe_bordercolor;
    }
    th {
      text-align: left;
    }
    td {
      text-align: center;
    }
    tr > :last-child {
      border-right-width: 0 !important;
    }
  }
  &:not(.stripe-reverse) tr:nth-child(odd) {
    th,
    td {
      background-color: $stripe_color;
      border-right: 1px solid $stripe_white;
    }
  }
  &.stripe-reverse tr:nth-child(even) {
    th,
    td {
      background-color: $stripe_color;
      border-right: 1px solid $stripe_white;
    }
  }
  tfoot {
    th,
    td {
      background-color: $stripe_color;
      border-top: 3px solid #7f7f7f;
    }
  }
}

figure {
  position: relative;
  img {
    max-width: 100%;
    margin-bottom: 20px;
  }
  figcaption {
    font-size: 10pt;
    line-height: 1.5em;
  }

  &.right-text {
    img {
      max-width: 66%;
    }
    figcaption {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60%;
    }
  }
}

ul {
  li {
    list-style: none;
  }
}

/* 印刷用の調整 */
@media print {
  body {
    width: 210mm; /* 用紙の横幅を改めて指定 Chrome用 */
    -webkit-print-color-adjust: exact;
    margin: 0 auto;
    color-adjust: exact;
    line-height: 1.5em;
  }
  nav {
    display: none !important;
  }
}

.global-nav {
  display: flex;
  width: 210mm;
  height: 30px;
  margin: 0 auto;
  flex-direction: row;
  li {
    width: 100px;
    background-color: #eeeeee;
  }
  a {
    display: block;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    color: #333333;
    &.active {
      color: #ffffff;
      background-color: #da3c41;
    }
  }
}

// 1ページあたりの設定
.report-page {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 210mm;
  height: 297mm;
  page-break-after: always;
  background-color: #ffffff;
  &:last-child {
    page-break-after: auto;
  }
}

@media screen {

  .report-pages {
    position: relative;
    z-index: 2;
    background-color: #e0e0e0;
  }
  
  .report-page {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #dddddd;
  }
}

// ファイル選択
.select-file {
  display: block;
  margin-top: 30px;
  padding: 30px;
  line-height: 2em;
}
.select-file-title {
  margin-bottom: 10px;
}

// 章トップページ
.cover-page {
  position: relative;
  background-color: $theme_color;
  background-position: center;
  background-repeat: repeat;
  color: $theme_fontcolor;

  @media screen {
    background-image: url($back_pattern);
  }

  &::before,
  &::after {
    @include backLogo;
  }
  &::before {
    top: 3mm;
    left: 3mm;
  }
  &::after {
    bottom: 3mm;
    right: 3mm;
    background-position: right;
  }
  .cover-title {
    height: 220mm;
    line-height: 220mm;
    font-size: 30pt;
    text-align: center;
    text-decoration: underline;
  }
  .cover-description {
    flex: 1;
    border-top: 1px solid $theme_fontcolor;
    margin-left: 20mm;
    margin-right: 20mm;
    padding-top: 10mm;
    font-size: 17pt;
  }
}

// トップページ
.top-page {
  background-image: url($top_image);
  background-size: cover;
}

// ページタイトル
.page-title {
  background-color: $theme_color;

  @media screen {
    background-image: url($back_pattern);
  }
  .page-title-inner {
    line-height: 20mm;
    font-size: 21pt;
    text-align: center;
    color: $theme_fontcolor;
  }
}

.page-score {
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: 3mm;
  right: 5mm;
  height: 35mm;
}

// ページのコンテンツ
.page-content {
  position: relative;
  flex: 1;
  padding: 45mm 5mm 5mm;
  &::before {
    @include backLogo;
    height: 40mm;
    top: 3mm;
    left: 3mm;
    opacity: 0.4;
  }
}

// ページのサマリ
.page-summary {
  overflow: hidden;
  position: relative;
  height: 65mm;
  padding: 5mm;
  box-sizing: border-box;
  background-color: $theme_color;
  background-position: center;
  background-repeat: repeat;
  background-size: auto;
  color: $theme_fontcolor;

  @media screen {
    background-image: url($back_pattern);
  }

  &::before {
    @include backLogo;
    bottom: 5%;
    width: 20mm;
    max-height: 45mm;
    right: 3mm;
  }

  .summary-title {
    text-align: center;
    font-size: 21pt;
    padding-bottom: 2.5mm;
    border-bottom: 1px solid #ffffff;
  }
  .summary-description {
    box-sizing: border-box;
    padding: 2.5mm;
  }
}

// 表 + グラフの表示
.grid-table-graph {
  display: grid;
  grid-template-columns: 1fr 40mm;
  min-height: 135px;
  margin-bottom: 10px;
  .grid-table {
    table {
      margin-bottom: 0;
    }
  }
  .grid-graph {
    position: relative;
    margin-left: auto;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }

  .donutchart-wrapper {
    position: relative;
    &.is-empty {
      width: 116px;
      height: 116px;
      line-height: 116px;
      margin-top: 7px;
      margin-left: 7px;
      text-align: center;
      border: 1px solid #000000;
      border-radius: 50%;
    }
  }
  .donutchart-label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 63px;
    height: 63px;
    top: 32px;
    left: 32px;
    dt,
    dd {
      font-size: 6pt;
      line-height: 1.4em;
      text-decoration: underline;
    }
    dl:nth-child(1) {
      text-align: right;
    }
  }

  .donutchart-innertext {
    display: none !important;
  }
  .donutchart-arcs-path {
    opacity: 1 !important;
  }
}

// 左にタイトルがある表
.left-title-table {
  display: grid;
  grid-template-columns: 10mm 1fr;
  gap: 3mm;
  .title {
    background-color: $stripe_color;
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    font-weight: bold;
    font-size: 14pt;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    line-height: 10mm;
    text-align: left;
    padding-top: 3mm;
    letter-spacing: 2pt;
  }
  table {
    margin-bottom: 0;
  }
}

// インジケーター
.indicator {
  width: 35mm;
  height: 28mm;
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-align: center;
  font-size: 18pt;
  font-weight: bold;
  line-height: 30mm;
}

// 円グラフ
.chart-label {
  display: inline-block;
  position: relative;
  width: 4mm;
  height: 4mm;
  margin-right: 5px;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }

  &::before {
    z-index: 2;
    width: 3mm;
    height: 3mm;
    top: 0.5mm;
    left: 0.5mm;
  }
  &::after {
    z-index: 1;
    width: 4mm;
    height: 4mm;
    top: 0;
    left: 0;
    background-color: #ffffff;
  }

  &.color1::before {
    background-color: #000000;
  }
  &.color2::before {
    background-color: #7f7f7f;
  }
  &.color3::before {
    background-color: #d9d9d9;
  }
}

.code-row {
  display: block;
  text-align: left;
  padding-left: 20px;
  .no-padding & {
    padding-left: 0;
  }
}

.html-code {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  min-height: 10em;
  max-height: 13.5em;

  .code-row:first-child,
  .code-row:last-child {
    padding-left: 0;
  }
}

.red-text {
  color: red;
}
